<template>
  <div>
    <Modal :options="{width: '40vw', close: true}" @close="$emit('close')">
        <div class="title">Agregar método de contacto</div>
        <div class="body">
            <div class=" row form-group">
                <label for="tipo" class="col-form-label col-sm-2">Tipo</label>
                <div class="col-sm-10">
                    <select v-model="agregar_contacto.tipo" name="contacto_tipo" id="contacto_tipo" class="form-control">
                        <option value="null">Selecciona una opción</option>
                        <option v-for="contacto in metodo_contacto" :key="contacto.id" :value="contacto.valor">{{ contacto.nombre }}</option>
                    </select>                    
                </div>
            </div>
            <div class="row form-group">
                <label for="contacto_valor" class="col-form-label col-sm-3">Valor</label>
                <div class="col-sm-9">
                    <div class="input-group">
                        <div v-if="agregar_contacto.tipo != 'email'" class="input-group-prepend">
                            <div class="input-group-text">+52</div>
                        </div>
                        <input v-model="agregar_contacto.valor" type="text" class="form-control"  name="contacto_valor" id="contacto_valor" :placeholder="agregar_contacto.tipo == 'email' ? 'correo@mail.com' : 'Número a 10 dígitos'" autocomplete="off">
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button type="button" class="btn btn-primary mr-2" @click="agregar"> Agregar </button>
              <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>        
    </Modal>


  </div>
</template>

<script>
import Modal from '@/components/Modal';
import apiClientes from "@/apps/clientes/api/clientes";

export default {
    components:{
        Modal,
    },
    props:{
        metodo_contacto:{
            type:Array,
            required: true,
        },
        ticket:{
            type:Object,
            required:true,
        },                      
    },
    data(){
        return{
            agregar_contacto:{
                tipo:null,
                valor:null,
                validado:true,
                principal: 1,
                preferido: 0,
				facturas: 0,
            },
        }   
    },
    methods:{
        async agregar(){
            try {
                await apiClientes.crear_contacto_tickets(this.ticket.cliente_id, this.agregar_contacto);
                this.$helper.showMessage('Exito!','Se creo el método de contacto correctamente');
                this.$emit('close');
            }catch(e) {
                this.$log.info('err',e);
                this.$helper.showAxiosError(e,'Error');
            }            
        }
    },
}
</script>

<style>

</style>